<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Başlık"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="itemData.title"
              placeholder="Başlık"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col
        cols="12"
      >
        <b-card title="Görsel">
          <b-img
            v-if="itemData.filename"
            :src="imageUrl + '/media/gallery/' + itemData.filename"
            thumbnail
            center
          />
          <div class="text-center mt-1">
            <b-button
              variant="primary"
              @click="setModal('gallery')"
            >
              <FeatherIcon icon="UploadIcon" />
              Görsel Yükle
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <cropper-image
      v-if="cropperSettings.type"
      :modal-status="cropperSettings.modalStatus"
      :close-modal="closeModal"
      :file-name="cropperSettings.fileName"
      :folder="cropperSettings.folder"
      :ratio="cropperSettings.ratio"
      @uploadImage="setUploadImage"
    />
    <b-button
      variant="primary"
      :disabled="itemData.submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BCol, BFormGroup, BFormInput, BImg, BRow, BButton,
} from 'bootstrap-vue'
import CropperImage from '@/layouts/components/common/CropperImage.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BButton,
    CropperImage,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      imageUrl: this.$store.state.app.baseURL,
      cropperSettings: {
        modalStatus: false,
        fileName: null,
        folder: null,
        ratio: null,
        type: null,
      },
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['imageGallery/dataItem']
    },
  },
  methods: {
    setModal(type) {
      if (type === 'gallery') {
        this.cropperSettings = {
          modalStatus: true,
          fileName: 'gallery-image.jpg',
          folder: 'gallery',
          ratio: 1280 / 800,
          type: 'gallery',
        }
      }
    },
    closeModal() {
      this.cropperSettings = {
        modalStatus: false,
        fileName: null,
        folder: null,
        ratio: null,
        type: null,
      }
    },
    setUploadImage(item) {
      if (this.cropperSettings.type === 'gallery') {
        this.itemData.filename = item.image
        this.itemData.thumb = item.thumb
      }
      this.cropperSettings.modalStatus = false
    },
  },
}
</script>
